import { ClassData, ClassSchedule } from "../types/class.type";
import { DateTimeUtils } from "../utils/datetime.utils";

/**
 * Service for managing class schedules.
 */
export class ClassScheduleService {


    /**
     * Retrieves the first class schedule by class ID from the given class data.
     * 
     * @param classData - The class data containing the class schedules.
     * @param classId - The ID of the class to search for.
     * @returns The first class schedule matching the given class ID, or undefined if not found.
     */
    static getFirstClassScheduleByClassId(classData: ClassData, classId: number): ClassSchedule | undefined {
        if (classData) {
            return classData.class_schedules.find(schedule => schedule.class_id === classId);
        }
        return undefined;
    }


    /**
     * Retrieves a class schedule from the given class data based on the target time.
     * @param classData - The class data containing the schedules.
     * @param targetTime - The target time to search for in the schedules.
     * @returns The class schedule that matches the target time, or undefined if not found.
     */
    static getClassScheduleByTime(classData: ClassData, targetTime: string): ClassSchedule | undefined {
        if (classData) {
            return classData.class_schedules.find(schedule => schedule.start_time === targetTime);
        }
        return undefined;
    }

   /**
 * Retrieves the next class schedule based on the provided class data and optional date.
 * 
 * @param classData - The class data containing the class schedules.
 * @param date - Optional date to use for comparison. If not provided, the current date will be used.
 * @returns The next class schedule or, if none found today, the earliest class schedule.
 */
static nextClassSchedule(classData: ClassData, date?: Date): ClassSchedule | undefined {
    if (classData) {
        const classSchedules = classData.class_schedules;
        const now = date ? date : new Date();

        // Filter schedules that are in the future for the current day
        const futureSchedules = classSchedules.filter(schedule => {
            const scheduleDate = DateTimeUtils.hoursToTime(schedule.start_time);
            return scheduleDate > now;
        });

        if (futureSchedules.length > 0) {
            // Find the next class on the current day
            const nextClass = futureSchedules.reduce((prev, current) =>
                DateTimeUtils.hoursToTime(current.start_time) < DateTimeUtils.hoursToTime(prev.start_time) ? current : prev
            );
            return nextClass;
        }

        // If no future class found today, return the earliest class overall
        const earliestClass = classSchedules.reduce((prev, current) =>
            DateTimeUtils.hoursToTime(current.start_time) < DateTimeUtils.hoursToTime(prev.start_time) ? current : prev
        );

        return earliestClass;
    }
    return undefined;
}

} 