.gif {
  align-items: center;
  background-color: #1b1b1b;
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  position: relative;
}

.gif .movement-image {
  max-width: 350px;
  max-height: 350px;
}

.gif .text {
  align-self: stretch;
  color: #f9f9f9;
  font-family: var(--title-hero-font-family);
  font-size: var(--title-hero-font-size);
  font-style: var(--title-hero-font-style);
  font-weight: var(--title-hero-font-weight);
  letter-spacing: var(--title-hero-letter-spacing);
  line-height: var(--title-hero-line-height);
  position: relative;
  text-align: center;
}