import React from "react";
import "./Header.css";
import { Day } from "../../Days";
import { useTranslation } from "react-i18next";

interface HeaderProps {
  icon: string;
  title: string;
  date?: string;
  time?: string;
}

const Header: React.FC<HeaderProps> = ({
  icon,
  title,
  date,
  time,
}: HeaderProps): React.JSX.Element => {

  const { t } = useTranslation(); // useTranslation hook

  // Split title into words, translate each word, and then join them back
  const translatedTitle = title
    .split(" + ") // Split the title into individual words
    .map((word) => t(`exercise_type.` + word.trim())) // Translate each word separately
    .join(" + "); // Join them back with a space

  return (
    <div className="header">
      <div className="title">
        <img className="dumbbell1" alt="Workout Icon" src={icon} />
        <div className="text">{translatedTitle}</div>
      </div>
      {(date && time) && (
        <Day dateLabel={date} time={time} />
      )}
    </div>
  );
};

export default Header;