import React, { useEffect, useState } from "react";
import { DateTimeUtils } from "../../utils/datetime.utils";
import SoundPlayer from "../../components/vertical/SoundPlayer/SoundPlayer";
import { WorkoutScreenData } from "../../types/screen.type";
import { ProgressBar } from "../../components/vertical/ProgressBar";
import { Videos } from "../../components/vertical/Video";
import { Header } from "../../components/vertical/Header";
import { Hydrate } from "../../components/vertical/Hydrate";
import { ChangeStation } from "../../components/vertical/ChangeStation";
import "./Exercise.css";
import { Loading, ProgressCircle, Sets, Stay, PersonalTrainer } from "../../components";
import { Notification2 } from "../../components/vertical/Notification";
import { TimerControl } from "../../types/timer.type";
import { Finish } from "../../components/vertical/Finish";

interface ExerciseProps {
  screenData?: WorkoutScreenData;
  timerControlRef?: React.RefObject<TimerControl>;
  onLoad?: () => void;
}

export const Exercise: React.FC<ExerciseProps> = ({ screenData, timerControlRef, onLoad }): React.JSX.Element => {
  const [timeLeft, setTimeLeft] = useState<number | null>(null);

  useEffect(() => {
    if (screenData && screenData.type !== "work" && screenData.progressCircle && screenData.progressCircle.initialTotalTime !== undefined) {
			setTimeLeft(screenData.progressCircle.initialTotalTime); // Initialize with the total time
    }
  }, [screenData, screenData?.progressCircle?.initialTotalTime]);

  useEffect(() => {
    if (timeLeft !== null && timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime !== null ? prevTime - 1 : null));

      }, 1000); // Decrease the time every second

      return () => clearInterval(timer); // Cleanup timer on component unmount
    }
  }, [timeLeft]);

  useEffect(() => {
    if (onLoad) {
      onLoad(); // Notify that this component has finished loading
    }
  }, [onLoad]);

  if (!screenData) {
    return (
      <div className="workout">
        <div className="container-wrapper">
          <div className="container">
            <Loading message="Loading..." />
          </div>
        </div>
      </div>
    );
  } else {
    const showVideos = (screenData.type !== "rest" && screenData.type !== "hydrate") ? true : timeLeft && timeLeft <= (screenData.type === "rest" ? 6 : 15);
    const showStayOrChangeStation = screenData.type === "rest" && timeLeft && timeLeft > 6;
    const showHydrate = screenData.type === "hydrate" && timeLeft && timeLeft > 15;

    return (
      <div className="workout">
        <div className="container-wrapper">
          <div className="container">
            {screenData.header?.show && (
              <Header
                icon={screenData.header.icon}
                title={screenData.header.title} // Use translated workout title
                date={DateTimeUtils.formatDateToPortuguese(screenData.header.date)}
                time={screenData.header.time} // Use formatted workout time
              />
            )}

            {screenData.progressCircle?.show && (
              <ProgressCircle
                ref={timerControlRef}
                initialTotalTime={screenData.progressCircle.initialTotalTime}
                color={screenData.progressCircle.color}
                bypassCacheGuid={screenData.progressCircle.bypassCacheGuid}
              />
            )}

            {screenData.progressBar?.show && (
              <ProgressBar
                total={screenData.progressBar.numberOfElements}
                progress={screenData.progressBar.progress}
                label={screenData.progressBar.label}
                time={screenData.progressBar.time}
                hideOnTimeLeft={screenData.progressBar.hideOnTimeLeft}
                bypassCacheGuid={Date.now().toString()}
              />
            )}

            {screenData.notification?.show && (
              <Notification2
                message={screenData.notification.message}
                time={screenData.notification.time}
                showOnTimeLeft={screenData.notification.showOnTimeLeft}
                bypassCacheGuid={Date.now().toString()}
              />
            )}

            {showStayOrChangeStation && screenData.sets?.show && (
              <Sets
                total={screenData.sets.total}
                active={screenData.sets.active}
              />
            )}

            {showStayOrChangeStation && screenData.stay && (
              <Stay message={screenData.stay?.message} />
            )}

            {showStayOrChangeStation &&screenData.changeStation &&(
              <ChangeStation message={screenData.changeStation?.message} />
            )}

            {showHydrate && screenData.batteryMedium && <Hydrate message={screenData.batteryMedium?.message} />}

            {showVideos && (
              <Videos
                exerciseStationVideos={screenData.videos?.exercisesStation}
                showStations={screenData.videos?.showStations}
								isPersonalTrainerLayout={screenData.personalTrainer ? true : false}
              />
            )}

            {screenData.finish?.show && (
              <Finish message={screenData.finish.message} />
            )}

            {screenData.personalTrainer?.show && (
              <PersonalTrainer
                name={screenData.personalTrainer.name}
                jobTitle={screenData.personalTrainer.job_title}
                photo={screenData.personalTrainer.photo}
              />
            )}

            <SoundPlayer
              soundFile="/sound/notification_alert_ping_bright.mp3"
              play={false}
            />
          </div>
        </div>
      </div>
    );
  }
};
