import React, { useEffect, useRef, useState } from "react";
import "./Videos.css";

// The transformUrl function to modify the video URL correctly
function transformUrl(url: string): string {
    const regex = /(https:\/\/[^/]+\/)exercises\/(\d+)(\.mp4)/;
    return url.replace(regex, "$1exercises_converted/540_540_$2$3");
}

// Function to check if a URL exists by sending a HEAD request
// Function to check if a URL exists by sending a HEAD request
async function urlExists(url: string): Promise<boolean> {
    try {
        const response = await fetch(url, { method: "HEAD" });

		if (response.status !== 200) {
			return false;
		}
        return response.ok;
    } catch (error) {
        // Handle CORS error or network issues without logging it to console
        if (error instanceof TypeError && error.message.includes("Failed to fetch")) {
            // This could be a CORS error or a network issue
        }
        return false; // Assume the URL does not exist
    }
}

type VideoProps = {
    url?: string;
    onLoad?: () => void;
    maxRetries?: number; // Optional: maximum number of retries on failure
};

const Video: React.FC<VideoProps> = ({ url, onLoad, maxRetries = 3 }: VideoProps): React.JSX.Element => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [retryCount, setRetryCount] = useState(0);
    const [finalUrl, setFinalUrl] = useState<string | undefined>(undefined);
    const [isUrlValid, setIsUrlValid] = useState<boolean>(false);

    // Handle when the URL changes, transform it, and check if it exists
    useEffect(() => {
        if (url) {
            const transformedUrl = transformUrl(url);

            // Check if the transformed URL exists
            urlExists(transformedUrl).then((exists) => {
                if (exists) {
                    setFinalUrl(transformedUrl); // Use transformed URL
                    setIsUrlValid(true);
                } else {
                    setFinalUrl(url); // Fall back to the original URL
                    setIsUrlValid(true);
                }

                // Once we have a valid URL, load the video
                if (videoRef.current) {
                    videoRef.current.load();
                    videoRef.current.play().catch(() => {
                        // Handle autoplay block, if any
                    });
                }
            });
        }
    }, [url]);

    // Effect to handle retries on video error
    useEffect(() => {
        if (retryCount > maxRetries) {
            console.warn("Max retries exceeded for video loading.");
            return;
        }
    }, [retryCount, maxRetries]);

    // Event handler for when the video loads successfully
    const handleLoadedData = () => {
        if (onLoad) {
            onLoad(); // Notify the parent component
        }
    };

    const handleError = () => {
        const video = videoRef.current;

        if (video?.error) {
            const error = video.error;

            // Log the error details
            switch (error.code) {
                case error.MEDIA_ERR_ABORTED:
                    console.error("The user aborted the video playback.");
                    break;
                case error.MEDIA_ERR_NETWORK:
                    console.error("A network error caused the video download to fail.");
                    break;
                case error.MEDIA_ERR_DECODE:
                    console.error("The video playback was aborted due to a corruption problem or because the video used features your browser did not support.");
                    break;
                case error.MEDIA_ERR_SRC_NOT_SUPPORTED:
                    console.error("The video could not be loaded because the format is not supported.");
                    break;
                default:
                    console.error("An unknown error occurred.");
                    break;
            }

            // Log the full error object for further debugging
            console.error("Error code:", error.code);
            console.error("Error message:", error.message || "No error message provided");
        }

        // Retry logic
        if (retryCount < maxRetries) {
            setTimeout(() => {
                setRetryCount((prev) => prev + 1); // Increment retry count
                video?.load(); // Reload video
                video?.play().catch(() => {
                });
            }, 500); // Retry after 500ms
        } else {
            console.warn("Max retries exceeded for video loading.");
        }
    };

    return (
        <video
            ref={videoRef}
            width="100%"
            autoPlay
            loop
            muted
            controls={false}
            onLoadedData={handleLoadedData} // Video loaded successfully
            onError={handleError} // Video loading failed
        >
            {isUrlValid && finalUrl && <source src={finalUrl} type="video/mp4" />}
            {!isUrlValid && <p>Video not available</p>}
            Your browser does not support the video tag.
        </video>
    );
};

export default Video;
