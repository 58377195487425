import i18n from '../locales/i18n';

/**
 * Changes the current language of the application and persists the selection to local storage.
 * 
 * @param language - The language code (e.g., 'en', 'pt') to set as the current language.
 */
export const changeLanguage = (language: string): void => {
    // Change the current language using the i18n internationalization framework
    i18n.changeLanguage(language);

    // Persist the language preference in local storage to retain it across sessions
    localStorage.setItem('language', language);
};

/**
 * Retrieves the user's preferred language from local storage.
 * 
 * @returns The stored language code, or null if no language preference is set.
 */
export const getStoredLanguage = (): string | null => {
    return localStorage.getItem('language');
};

/**
 * Initializes the application language based on the stored preference or falls back to a default language.
 * 
 * @param defaultLanguage - The language to use if no preference is found in local storage.
 */
export const initializeLanguage = (defaultLanguage: string): void => {
    const storedLanguage = getStoredLanguage();
    
    // Use stored language if available; otherwise, use the default
    if (storedLanguage) {
        changeLanguage(storedLanguage);
    } else {
        changeLanguage(defaultLanguage);
    }
};