import { BookingClasses } from "../types/booking.type";
import { ClassData } from "../types/class.type";
import { ConfigService } from "./config.service";
import log from '../utils/logger.utils';

/**
 * ApiService is a singleton class to fetch class, booking, and movement data from a remote server.
 * It ensures only one instance is used throughout the application and handles all API requests.
 */
export class ApiService {

    private logger: log.Logger;
    private static instance: ApiService;
    private baseUrl: string;
    private tvId: string | undefined;
    private studioCode: string | undefined;

    private constructor(config: ConfigService) {
        this.logger = log.getLogger('ApiService');
        this.baseUrl = config.getApiUrl();
        this.tvId = config.getTvId();
        this.studioCode = config.getStudioId();
    }

    /**
     * Returns the singleton instance of ApiService.
     * @param config - Instance of ConfigService.
     */
    public static getInstance(config: ConfigService): ApiService {
        if (!ApiService.instance) {
            ApiService.instance = new ApiService(config);
        }
        return ApiService.instance;
    }

    /**
     * Generalized method to fetch data from the API.
     * @param endpoint - API endpoint to fetch data from.
     * @param queryParams - Optional query parameters to append to the URL.
     * @returns A Promise that resolves to the data of type T or undefined if there's an error.
     */
    private async fetchData<T>(endpoint: string, queryParams?: string, withStudioCode?: boolean): Promise<T | undefined> {
        try {
            // Construct the full URL
            let url = withStudioCode ? `${this.baseUrl}/tvs/content/${encodeURIComponent(this.tvId || '')}${endpoint}?studio_code=${encodeURIComponent(this.studioCode || '')}` : `${this.baseUrl}/tvs/content/${encodeURIComponent(this.tvId || '')}${endpoint}?` ;
            if (queryParams) {
                url += `&${queryParams}`;
            }

            this.logger.debug(`Fetching data from ${url}`);

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            // Handle non-OK responses
            if (!response.ok) {
                this.logger.error(`Failed to fetch data from ${url}. Status: ${response.status}`);
                return undefined;
            }

            // Parse the response as JSON
            const data = await response.json();
            return data as T;
        } catch (error) {
            this.logger.error('Error fetching data:', error);
            return undefined;
        }
    }

    public async fetchClassData(date?: string, withStudioCode?: boolean ): Promise<ClassData | undefined> {
        const queryParams = date ? `date=${encodeURIComponent(date)}` : undefined;
        
        // Fetch data
        const classData = await this.fetchData<ClassData>("", queryParams, withStudioCode);
    
        if (classData && classData.class_schedules) {
            // Propagate the `date` field to each class schedule
            classData.class_schedules.forEach((schedule) => {
                schedule.date = classData.date;
            });
        }
    
        return classData;
    }

    /**
     * Fetches movement data for a specific date, if provided.
     * @param date - Optional date string to filter movement data.
     * @returns A Promise that resolves to ClassData or undefined in case of an error.
     */
    public async fetchMovementData(date?: string, withStudioCode?: boolean): Promise<ClassData | undefined> {
        const queryParams = date ? `date=${encodeURIComponent(date)}` : undefined;
        return this.fetchData<ClassData>("", queryParams, withStudioCode);
    }

    /**
     * Fetches booking data.
     * @returns A Promise that resolves to BookingClasses or undefined in case of an error.
     */
    public async fetchBookingData(date?: string): Promise<BookingClasses | undefined> {
        const queryParams = date ? `date=${encodeURIComponent(date)}` : undefined;
        return this.fetchData<BookingClasses>("", queryParams);
    }
}