import EventEmitter from 'eventemitter3';
import log from '../utils/logger.utils';
import eventsService from './events.service';
import { ConfigService } from './config.service';
import { ClassSchedule } from '../types/class.type';
import { ExerciseStationVideo } from '../types/exercise.type';
import { RenderEvents } from '../types/events.type';
import { RenderItem, RenderItemType } from '../types/render.types';
import { TimerScreenData, WorkoutModulesHeader, WorkoutModulesNotification, WorkoutModulesPersonalTrainer, WorkoutModulesProgressBar, WorkoutModulesProgressCircle, WorkoutModulesVideos, WorkoutScreenData } from '../types/screen.type';
import { BookingScreenData } from '../types/booking.type';
import { MovementScreenData } from '../types/movement.type';
type EventHandler<T> = (message: T) => void;

export class RenderService extends EventEmitter {
    private logger: log.Logger;
    private screenData?: WorkoutScreenData;
    private bookingScreenData?: BookingScreenData;
    private configService: ConfigService;
    // A map that holds arrays of handlers for different event types
    private eventHandlers: Map<string, EventHandler<any>[]> = new Map();

    public static instance: RenderService;

    private constructor() {
        super();
        this.configService = ConfigService.getInstance();
        this.logger = log.getLogger('RenderService');
        this.registerEventListeners();
        this.logger.info('Initialized');
    }

    public static getInstance(): RenderService {
        if (!RenderService.instance) {
            RenderService.instance = new RenderService();
        }
        return RenderService.instance;
    }

    // Method to add a handler for a specific event type
    public addHandler<T>(eventType: string, handler: EventHandler<T>): void {
        if (!this.eventHandlers.has(eventType)) {
            this.eventHandlers.set(eventType, []);
        }
        this.eventHandlers.get(eventType)?.push(handler);
    }

    // Method to remove a handler for a specific event type
    public removeHandler<T>(eventType: string, handler: EventHandler<T>): void {
        const handlers = this.eventHandlers.get(eventType);
        if (handlers) {
            this.eventHandlers.set(eventType, handlers.filter(h => h !== handler));
        }
    }

    // Notify all handlers for a specific event type
    private notifyHandlers<T>(eventType: string, data: T): void {
        const handlers = this.eventHandlers.get(eventType);
        if (handlers) {
            handlers.forEach((handler, index) => {
                this.logger.trace(`Handler ${index + 1} for event type "${eventType}" is being called with data:`, data);
                handler(data);
            });
        } else {
            this.logger.debug(`No handlers found for event type "${eventType}".`);
        }
    }

    // Example: registering event listeners for multiple types of events
    public registerEventListeners(): void {
        const handleEvent = (event: RenderEvents, renderItem: RenderItem): void => {
            this.logger.debug(`Received ${event} event with data:`, renderItem);
            if (event === RenderEvents.bookingClassesWrapper) {
                if (renderItem.renderItemType === RenderItemType.BOOKING) {
                    this.bookingScreenData = this.renderItemBooking(renderItem);
                    this.notifyHandlers<BookingScreenData>("BookingScreenData", this.bookingScreenData);
                    return;
                }
                return;
            } else if (event === RenderEvents.classWorkoutStart) {
                this.screenData = this.renderItem(renderItem);
                this.notifyHandlers<WorkoutScreenData>("WorkoutScreenData", this.screenData);
                return;
            } else if (event === RenderEvents.classNextWorkout) {
                this.screenData = this.renderItem(renderItem);
                this.notifyHandlers<WorkoutScreenData>("WorkoutScreenData", this.screenData);
                return;
            } else if (event === RenderEvents.movementDataUpdated) {
                if (renderItem.renderItemType === RenderItemType.MOVEMENT) {
                    let movementScreenData = this.renderItemMovement(renderItem);
                    this.notifyHandlers<MovementScreenData>("MovementScreenData", movementScreenData);
                    return;
                }
                return;
            } else if (event === RenderEvents.timerNextWorkout) {
                if (renderItem.renderItemType === RenderItemType.TIMER_NEXT_WORKOUT) {
                    let timerScreenData = this.renderTimerNextWorkout(renderItem);
                    this.notifyHandlers<TimerScreenData>("TimerScreenData", timerScreenData);
                    return;
                }
                return;
            } else if (event === RenderEvents.timerStartWorkout) {
                if (renderItem.renderItemType === RenderItemType.TIMER) {
                    let timerScreenData = this.renderTimerWorkout(renderItem);
                    this.notifyHandlers<TimerScreenData>("TimerScreenData", timerScreenData);
                    return;
                }
                return;
            }
        };

        const eventsToRegister = [
            RenderEvents.classNextWorkout,
            RenderEvents.classWorkoutAboutToStart,
            RenderEvents.classWorkoutStart,
            RenderEvents.bookingClassesWrapper,
            RenderEvents.movementDataUpdated,
            RenderEvents.timerNextWorkout,
            RenderEvents.timerStartWorkout,
        ];

        eventsToRegister.forEach(event => {
            eventsService.on(event, (renderItem: RenderItem) => handleEvent(event, renderItem));
        });
    }


    // #region Methods
    private renderItemBooking(renderItem: RenderItem): BookingScreenData {
        if (!renderItem) {
            return {};
        }
        let bookingScreenData: BookingScreenData = {
            bookingClasses: renderItem.renderItemInfo?.bookingClassesWrapper?.bookingClasses,
            imgPath: this.configService.getFilesStorageUrl(),
        };
        return bookingScreenData ? bookingScreenData : {};
    }

    private renderItem(renderItem: RenderItem): WorkoutScreenData {
        if (!renderItem) {
            return {};
        }

        switch (renderItem.renderItemType) {
            case RenderItemType.EXERCISE_NEXT_WORKOUT:
                return this.renderNextWorkout(renderItem);
            case RenderItemType.EXERCISE_ABOUT_TO_START:
                return this.renderAboutToStartWorkout(renderItem);
            case RenderItemType.EXERCISE:
                return this.renderWorkout(renderItem);
            //case RenderItemType.TIMER:
            //        return this.renderTimerWorkout(renderItem);
            default:
                this.logger.debug('No renderItemType data', renderItem, renderItem.renderItemType);
                return {};
        }

    }

    private renderItemMovement(renderItem: RenderItem): MovementScreenData {

        if (!renderItem || !renderItem.classSchedule) {
            return {};
        }
        let fs = this.configService.getFilesStorageUrl()
        let cs = renderItem.classSchedule;

        let workoutImage = cs.workout.image ? fs + cs.workout.image : '/img/default_workout_120x120.jpg';
        let movementImage = cs.workout.movement.image ? fs + cs.workout.movement.image : '/img/default_movement.jpeg';

        let movementScreenData: MovementScreenData = {
            workoutImage: workoutImage,
            stations: cs.workout.movement.stations,
            pods: cs.workout.movement.pods,
            movementImage: movementImage,
            name: cs.workout.movement.name,
            description: cs.workout.description,
        };
        return movementScreenData ? movementScreenData : {};

    }

    private renderNextWorkout(workoutRenderItem: RenderItem): WorkoutScreenData {
        if (!workoutRenderItem) {
            return {};
        }
        this.screenData = {
            header: this.composeWorkoutModulesHeader(workoutRenderItem),
            progressCircle: this.composeProgressCircle(workoutRenderItem),
            videos: this.composeWorkoutModulesVideos(workoutRenderItem),
            personalTrainer: this.composeWorkoutModulesPersonalTrainer(workoutRenderItem),
        };
        this.logger.debug('Rendered next workout with data', this.screenData);
        return this.screenData ? this.screenData : {};

    }

    private renderTimerNextWorkout(renderItem: RenderItem): TimerScreenData {
        if (!renderItem) {
            return {};
        }

        if (!renderItem.classSchedule) {
            return {};
        }

        let screenData = {
            isWorkout: false,
            header: {

                show: true,
                icon: '/img/goal.svg',
                title: renderItem.classSchedule?.workout.exercise_types.join(' + ') || '',
                date: renderItem.date,
                time: renderItem.time,

            },
            progressCircle: {
                show: true,
                initialTotalTime: renderItem.duration ? renderItem.duration : 0,
            },
            personalTrainer: {
                show: true,
                name: renderItem.classSchedule?.trainer.name || '',
                job_title: renderItem.classSchedule?.trainer.job_title || '',
                photo: this.getTrainerPhoto(renderItem.classSchedule),
            },
            workoutImage: this.getWorkoutPhoto(renderItem.classSchedule, 'medium'),

        };
        this.logger.debug('Rendered Timer next workout with data', screenData);
        return screenData ? screenData : {};

    }


    private renderAboutToStartWorkout(workoutRenderItem: RenderItem): WorkoutScreenData {
        if (!workoutRenderItem) {
            return {};
        }
        this.screenData = {
            header: this.composeWorkoutModulesHeader(workoutRenderItem),
            progressCircle: this.composeProgressCircle(workoutRenderItem),
            videos: this.composeWorkoutModulesVideos(workoutRenderItem),
        };

        return this.screenData ? this.screenData : {};

    }

    renderWorkout(item: RenderItem): WorkoutScreenData {
        // Check if the renderItemType is EXERCISE and there is exerciseTask info
        if (item.renderItemType !== RenderItemType.EXERCISE || !item.renderItemInfo || !item.renderItemInfo.exerciseTask) {
            return {};
        }

        const { exerciseTask } = item.renderItemInfo;
        const isWork = exerciseTask.type === 'work';
        const isRest = exerciseTask.type === 'rest';
        const isHydrate = exerciseTask.type === 'hydrate';
        const isStarting = exerciseTask.type === 'starting';
        const isEnding = exerciseTask.type === 'ending';

        const changeStation = exerciseTask.station_change;
        const remainingSets = exerciseTask.remaining_sets;
        const totalSets = exerciseTask.current_set + exerciseTask.remaining_sets;

        const title = item.classSchedule?.workout.exercise_types.join(' + ') || '';

        let workoutScreenData: WorkoutScreenData = {};

        if (isWork) {
            workoutScreenData = {
				type: 'work',
                // A.1: Show header without date and time
                header: {
                    show: true,
                    icon: '/img/dumbbell.svg', // Example icon, you can adjust based on your use case
                    title: title,
                },
                // A.2: Show progress circle with time and green color
                progressCircle: {
                    show: true,
                    initialTotalTime: item.renderItemInfo.duration || exerciseTask.time,
                    color: 'green',
                    bypassCacheGuid: new Date().getTime().toString()
                },
                // A.3 & A.4: Show progress bar with stations unless station change is true
                progressBar: this.getProgressBar(item),
                // A.4: Show notification when station change is true
                notification: this.getNotificationMessage(item),
                // A.5: Show videos with stations
                videos: this.composeWorkoutModulesVideos(item)

            };
        }

        if (isRest) {
            workoutScreenData = {
				type: 'rest',
                // B.1: Show header without date and time
                header: {
                    show: true,
                    icon: '/img/dumbbell.svg', // Example icon
                    title: title
                },
                // B.2: Show progress circle with time and red color
                progressCircle: {
                    show: true,
                    initialTotalTime: item.renderItemInfo.duration || exerciseTask.time,
                    color: 'red'
                },
                // B.3: Always hide the progress bar
                progressBar: undefined,
                //sets
                sets: changeStation ? undefined : {
                    show: true,
                    total: totalSets,
                    active: exerciseTask.current_set
                },
                // B.4: Show stay module if station change is true
                stay: !changeStation ? {
                    show: true,
                    message: 'station'
                } : undefined,
                changeStation: changeStation ? {
                    show: true,
                    message: 'station_move'
                } : undefined,
                // B.6: Show notification with remaining sets
                notification: changeStation ? undefined : {
                    show: true,
                    message: `${remainingSets}`
                },
                // B.7: Do not show videos
                videos: this.composeWorkoutModulesVideos(item)
            };
        }

        if (isHydrate) {
            workoutScreenData = {
				type: 'hydrate',
                // B.1: Show header without date and time
                header: {
                    show: true,
                    icon: '/img/dumbbell.svg', // Example icon
                    title: title
                },
                // B.2: Show progress circle with time and red color
                progressCircle: {
                    show: true,
                    initialTotalTime: item.renderItemInfo.duration || exerciseTask.time,
                    color: 'red'
                },
                batteryMedium: {
                    show: true,
                    message: 'recover'
                },
                progressBar: undefined,
                sets: undefined,
                stay: undefined,
                changeStation: undefined,
                notification: undefined,
                videos: this.composeWorkoutModulesVideos(item)
            };
        }

        if (isStarting) {
            workoutScreenData = {
				type: 'starting',
                header: {
                    show: true,
                    icon: '/img/dumbbell.svg',
                    title: title
                },
                progressCircle: {
                    show: true,
                    initialTotalTime: item.renderItemInfo.duration || exerciseTask.time,
                    color: 'red'
                },
                progressBar: undefined,
                sets: undefined,
                stay: undefined,
                changeStation: undefined,
                notification: {
                    show: true,
                    message: 'get_ready'
                },
                videos: this.composeWorkoutModulesVideos(item),
            };
        }

        if (isEnding) {
            workoutScreenData = {
				type: 'ending',
                header: {
                    show: true,
                    icon: '/img/dumbbell.svg',
                    title: title
                },
                progressCircle: {
                    show: true,
                    initialTotalTime: 0,
                    color: 'red'
                },
                progressBar: undefined,
                sets: undefined,
                stay: undefined,
                changeStation: undefined,
                notification: undefined,
                finish: {
                    show: true,
                    message: 'finish'
                },
                videos: undefined
            };
        }

        return workoutScreenData;
    }


    renderTimerWorkout(item: RenderItem): TimerScreenData {

        // Check if the renderItemType is EXERCISE and there is exerciseTask info
        if (item.renderItemType !== RenderItemType.TIMER || !item.renderItemInfo || !item.renderItemInfo.exerciseTask) {
            return {};
        }

        const { exerciseTask } = item.renderItemInfo;
        const isWork = exerciseTask.type === 'work';
        const isRest = exerciseTask.type === 'rest';
        const isHydrate = exerciseTask.type === 'hydrate';
        const isStarting = exerciseTask.type === 'starting';
        const isEnding = exerciseTask.type === 'ending';

        const title = item.classSchedule?.workout.exercise_types.join(' + ') || '';

        let workoutScreenData: TimerScreenData = {};

        if (isWork || isRest || isHydrate) {
            workoutScreenData = {
                isWorkout: true,
                isWorkoutStarting: false,
                workoutName: title,

                progressCircle: {
                    show: true,
                    initialTotalTime: item.renderItemInfo.duration || exerciseTask.time,
                    color: 'green',
                    bypassCacheGuid: new Date().getTime().toString()
                },

                totalTime: item.renderItemInfo.exerciseTask.total_time || exerciseTask.time,
                set: item.renderItemInfo.exerciseTask.sets_accumulated,
                totalSets: item.renderItemInfo.exerciseTask.sets_total,
                hydrate_active: item.renderItemInfo.exerciseTask.hydrate_active,
                hydrate_total: item.renderItemInfo.exerciseTask.hydrate_total,
                type: item.renderItemInfo.exerciseTask.type,

            };
        }

        if (isStarting) {
            workoutScreenData = {
                isWorkout: true,
                isWorkoutStarting: true,
                progressCircle: {
                    show: true,
                    initialTotalTime: item.renderItemInfo.duration || exerciseTask.time,
                    color: 'red'
                },
            };
        }

        if (isEnding) {
            workoutScreenData = {
                isWorkout: true,
                isWorkoutStarting: false,
                progressCircle: undefined,
                workoutName: title,
                set: item.renderItemInfo.exerciseTask.sets_total,
                totalSets: item.renderItemInfo.exerciseTask.sets_total,
                type: item.renderItemInfo.exerciseTask.type,
            };
        }

        return workoutScreenData;
    }


    private composeWorkoutModulesPersonalTrainer(workoutRenderItem: RenderItem): WorkoutModulesPersonalTrainer {
        const cs = workoutRenderItem.classSchedule;
        if (!cs) {
            return {
                show: false,
                name: "",
                job_title: "",
                photo: "",
            };
        }

        const show = workoutRenderItem.renderItemType === RenderItemType.EXERCISE_NEXT_WORKOUT ? true : false;
        const trainerPhoto = this.getTrainerPhoto(cs);

        const workoutModulesPersonalTrainer: WorkoutModulesPersonalTrainer = {
            show: show,
            name: cs.trainer.name,
            job_title: cs.trainer.job_title,
            photo: trainerPhoto,
        };
        return workoutModulesPersonalTrainer;
    }

    private composeWorkoutModulesVideos(renderItem: RenderItem): WorkoutModulesVideos {
        const vid: WorkoutModulesVideos = {
            show: false,
            showStations: false,
            exercisesStation: [],
        };

        const classSchedule = renderItem.classSchedule;

        if (!classSchedule) {
            return {
                show: false,
                showStations: false,
                exercisesStation: []
            };
        }

        if (renderItem.renderItemType === RenderItemType.EXERCISE_NEXT_WORKOUT) {
            vid.show = true;
            vid.showStations = true;
            vid.exercisesStation = this.composeExerciseInfo(classSchedule);
        } else if (renderItem.renderItemType === RenderItemType.EXERCISE_ABOUT_TO_START) {
            vid.show = true;
            vid.showStations = true;
            vid.exercisesStation = this.composeExerciseInfo(classSchedule);
        } else if (renderItem.renderItemType === RenderItemType.EXERCISE) {
            let show = true;
            if (renderItem.renderItemInfo?.exerciseTask?.type === 'rest' || renderItem.renderItemInfo?.exerciseTask?.type === 'hydrate') {
                show = false;
            }
            vid.show = show;
            vid.showStations = true;
            vid.exercisesStation = this.composeExerciseInfo(classSchedule);
        }
        return vid;
    }




    private composeProgressCircle(renderItem: RenderItem): WorkoutModulesProgressCircle {
        const pg: WorkoutModulesProgressCircle = {
            show: false,
            initialTotalTime: 0,
        };
        if (!renderItem) {
            return pg;
        }

        if (renderItem.renderItemType === RenderItemType.EXERCISE_NEXT_WORKOUT) {
            pg.show = true;
            pg.initialTotalTime = renderItem.duration ? renderItem.duration : 0;
        } else if (renderItem.renderItemType === RenderItemType.EXERCISE_ABOUT_TO_START) {
            pg.show = true;
            pg.initialTotalTime = renderItem.duration ? renderItem.duration : 0;
        } else if (renderItem.renderItemType === RenderItemType.EXERCISE) {
            pg.show = true;
            pg.initialTotalTime = renderItem.renderItemInfo?.exerciseTask?.time || 0;
            pg.color = renderItem.renderItemInfo?.exerciseTask?.type === 'work' ? 'green' : 'red';
        }

        return pg;
    }

    private composeWorkoutModulesHeader(renderItem: RenderItem): WorkoutModulesHeader {
        const cs = renderItem.classSchedule;
        const header: WorkoutModulesHeader = {
            show: false,
            icon: "",
            title: "",
            date: "",
            time: "",
        };

        if (!cs) {
            return header
        }

        if (renderItem.renderItemType === RenderItemType.EXERCISE_NEXT_WORKOUT) {
            header.show = true;
            header.icon = "/img/goal.svg";
            header.title = cs.workout.exercise_types.join(' + ');
            header.date = renderItem.date;
            header.time = renderItem.time;
        } else if (renderItem.renderItemType === RenderItemType.EXERCISE_ABOUT_TO_START) {
            header.show = true;
            header.icon = "/img/goal.svg";
            header.title = cs.workout.exercise_types.join(' + ');
            header.date = renderItem.date;
            header.time = renderItem.time;

        } else if (renderItem.renderItemType === RenderItemType.EXERCISE) {
            header.show = true;
            header.icon = "/img/dumbbell.svg";
            header.title = cs.workout.exercise_types.join(' + ');
            header.date = "";
            header.time = "";
        };

        return header;
    }

    private composeExerciseInfo(classSchedule: ClassSchedule): ExerciseStationVideo[] {
        const exerciseInfoList: ExerciseStationVideo[] = [];
        const workout = classSchedule?.workout;
        if (workout && workout.exercises) {
            for (const exerciseItem of workout.exercises) {
                const exercise = exerciseItem.exercise;
                exerciseInfoList.push({
                    id: exercise.id,
                    name: exercise.name,
                    video: this.configService.getFilesStorageUrl() + exercise.video,
                    station_number: exerciseItem.station_number.toString(),
                });
            }
        }
        return exerciseInfoList;
    }
    // #endregion


    // #region Aux Methods

    private getTrainerPhoto(cs: ClassSchedule) {
        return (this.configService.getFilesStorageUrl() && cs.trainer.profile_image) ? this.configService.getFilesStorageUrl() + cs.trainer.profile_image : undefined;
    }

    private getWorkoutPhoto(cs: ClassSchedule, size: 'small' | 'medium' | 'large' = 'small') {

        let defaultImage = size === 'medium' ? '/img/default_workout_480x480.png' : '/img/default_workout.jpeg';
        return (this.configService.getFilesStorageUrl() && cs.workout.image) ? this.configService.getFilesStorageUrl() + cs.workout.image : defaultImage;
    }

    private getNotificationMessage(item: RenderItem): WorkoutModulesNotification {

        let notification: WorkoutModulesNotification = {
            show: false,
            message: '',
            time: 0,
            showOnTimeLeft: 0,
        };

        if (!item.renderItemInfo || !item.renderItemInfo.exerciseTask) {
            return notification;
        }

        notification.show = true;
        notification.showOnTimeLeft = 15;
        notification.time = item.renderItemInfo.exerciseTask.time;

        if (item.renderItemInfo.exerciseTask.pod_change) {
            notification.message = 'set_pod_move';
        }
        else if (!item.renderItemInfo.exerciseTask.station_change && item.renderItemInfo.exerciseTask.remaining_sets !== 0) {
            notification.message = 'set_station';
        } else if (item.renderItemInfo.exerciseTask.station_change && item.renderItemInfo.exerciseTask.remaining_sets === 0) {
            notification.message = 'set_station_move';
        }

        return notification;
    }

    private getProgressBar(item: RenderItem): WorkoutModulesProgressBar {
        let pg: WorkoutModulesProgressBar = {
            show: false,
            numberOfElements: 0,
            progress: 0,
            label: ''
        };

        if (!item.renderItemInfo || !item.renderItemInfo.exerciseTask) {
            return pg;
        }

        pg.label = 'sets';
        pg.show = true;
        pg.numberOfElements = item.renderItemInfo.exerciseTask.sets_total || 0;
        pg.progress = item.renderItemInfo.exerciseTask.sets_accumulated || 0;

        pg.hideOnTimeLeft = item.renderItemInfo.exerciseTask.isAmrap ? 0 : 15;
        pg.time = item.renderItemInfo.exerciseTask.time;

        return pg;

    }

    // #endregion
}
