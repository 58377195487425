import { DateTime } from 'luxon';

/**
 * Utility class for date and time manipulation.
 */
export class DateTimeUtils {

    /**
     * Calculates the number of seconds between the current time and a given target time.
     * If the target time is earlier than the current time, it assumes the target time is on the next day.
     *
     * @param targetTime - The target time in 'HH:MM:SS' format.
     * @returns The number of seconds until the target time.
     */
    static calculateSecondsToTime(targetTime: string): number {
        // Get the current time
        const now = new Date();

        // Convert targetTime string to Date object
        let targetDate = DateTimeUtils.hoursToTime(targetTime);

        // If target time is earlier than now, assume the target time is the next day
        if (targetDate.getTime() < now.getTime()) {
            // Add one day (24 hours in milliseconds)
            targetDate.setDate(targetDate.getDate() + 1);
        }

        // Calculate the difference in seconds
        const differenceInMillis = targetDate.getTime() - now.getTime();
        return Math.floor(differenceInMillis / 1000);
    }

    /**
     * Converts a string in the format 'HH:MM:SS' to a Date object using today's date.
     *
     * @param hoursString - The string to convert to a Date object.
     * @returns A Date object representing the time on today's date.
     */
    public static hoursToTime2(hoursString: string): Date {
        const [targetHours, targetMinutes, targetSeconds] = hoursString.split(':').map(Number);

        // Create a Date object for the target time with today's date
        let targetDate = new Date();
        targetDate.setHours(targetHours, targetMinutes, targetSeconds, 0);
        return targetDate;
    }

    /**
     * Formats a time value in seconds into a string representation.
     * If the time exceeds 3600 seconds, it returns 'HH:MM:SS'.
     * If the time is between 60 and 3600 seconds, it returns 'MM:SS'.
     * Otherwise, it returns 'SS'.
     *
     * @param seconds - The time in seconds to format.
     * @returns The formatted time string.
     */
    static formatTime(seconds: number): string {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        return seconds > 3600
            ? `${hrs.toString().padStart(2, "0")}:${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`
            : seconds >= 60
                ? `${mins.toString().padStart(2, "0")}:${secs.toString().padStart(2, "0")}`
                : secs.toString();
    }

    /**
     * Formats a date string to Portuguese locale in the format 'Weekday, DD/MM'.
     *
     * @param dateString - The ISO date string to format.
     * @returns The formatted date string in Portuguese locale.
     */
    static formatDateToPortuguese(dateString: string | undefined): string | undefined {
        if (!dateString) return undefined;

        const date = DateTime.fromISO(dateString).setLocale('pt');
        return date.toFormat("cccc, dd/MM");
    }

    /**
     * Formats a time string to 'HH:mm' format using Luxon.
     *
     * @param time - The time string in 'HH:MM:SS' format.
     * @returns The formatted time string in 'HH:mm' format.
     */
    static formatTimeToHHMM(time: string): string {
        const dateTime = DateTime.fromISO(`1970-01-01T${time}`, { zone: 'local' });
        return dateTime.toFormat('HH:mm');
    }

    /**
     * Gets the day of the week for a given date string.
     *
     * @param dateString - The date string to extract the day from.
     * @returns The day of the week as a string.
     */
    static getDayOfWeek(dateString: string): string {
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const date = new Date(dateString);
        return daysOfWeek[date.getDay()];
    }

    /**
     * Formats a date string to 'DD/MM' format.
     *
     * @param dateString - The date string to format.
     * @returns The formatted date string in 'DD/MM' format.
     */
    static formatDate(dateString: string): string {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        return `${day}/${month}`;
    }


    /**
         * Combines a date string and a time string into a Date object.
         * If dateStr is undefined, it defaults to today's date.
         * @param dateStr - The date string in "yyyy-mm-dd" format, or undefined.
         * @param timeStr - The time string in "hh:mm:ss" format.
         * @returns A Date object representing the combined date and time.
         */
    static combineDateAndTime(dateStr: string | undefined, timeStr: string): Date {
        if (!dateStr) {
            // Use today's date
            const today = new Date();
            dateStr = today.toISOString().split('T')[0]; // Format: "yyyy-mm-dd"
        }

        // Handle time strings without seconds
        if (/^\d{2}:\d{2}$/.test(timeStr)) {
            timeStr += ':00';
        }

        const dateTimeStr = `${dateStr}T${timeStr}`;
        const dateTime = new Date(dateTimeStr);

        if (isNaN(dateTime.getTime())) {
            throw new Error(`Invalid date or time format: date="${dateStr}", time="${timeStr}"`);
        }

        return dateTime;
    }


       /**
     * Converts a time string ("hh:mm:ss") to a Date object representing today at that time.
     * @param timeStr - The time string in "hh:mm:ss" format.
     * @returns A Date object representing today at the given time.
     */
       static hoursToTime(timeStr: string): Date {
        const now = new Date();
        const [hours, minutes, seconds] = timeStr.split(':').map(Number);

        // Create a new Date object for today with the specified time
        const dateTime = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate(),
            hours,
            minutes,
            seconds || 0,
            0
        );

        if (isNaN(dateTime.getTime())) {
            throw new Error(`Invalid time format: "${timeStr}"`);
        }

        return dateTime;
    }
}