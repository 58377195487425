/**
 * Utility class for handling URL query strings.
 */
export class QueryString {

    private params: URLSearchParams;

    /**
     * Initializes the QueryString instance with the current window's search parameters.
     */
    constructor() {
        this.params = new URLSearchParams(window.location.search);
    }

    /**
     * Retrieves the value associated with the given query string key.
     * @param key - The key to search for in the query string.
     * @returns The value as a string, or null if the key is not found.
     */
    get(key: string): string | null {
        return this.params.get(key);
    }

    /**
     * Sets the value for a specific key in the query string.
     * @param key - The key to be set in the query string.
     * @param value - The value to associate with the key.
     */
    set(key: string, value: string): void {
        this.params.set(key, value);
    }

    /**
     * Converts the current query parameters to a string.
     * @returns The query parameters as a URL-encoded string.
     */
    toString(): string {
        return this.params.toString();
    }

    /**
     * Static helper method to retrieve a query parameter by key from the current URL.
     * @param key - The query parameter key.
     * @returns The value associated with the key, or null if not found.
     */
    private static getParam(key: string): string | null {
        const params = new URLSearchParams(window.location.search);
        return params.get(key);
    }

    /**
     * Retrieves the 'tv' query parameter from the URL.
     * @returns The 'tv' parameter value, or null if not found.
     */
    static getTvId(): string | null {
        return QueryString.getParam('tv');
    }

    /**
     * Retrieves the 'class_schedule_date' query parameter from the URL.
     * @returns The 'class_schedule_date' parameter value, or null if not found.
     */
    static getClassScheduleDate(): string | null {
        return QueryString.getParam('class_schedule_date');
    }

    /**
     * Retrieves the 'class_schedule_id' query parameter from the URL.
     * @returns The 'class_schedule_id' parameter value, or null if not found.
     */
    static getClassScheduleId(): string | null {
        return QueryString.getParam('class_schedule_id');
    }

    /**
     * Retrieves the 'class_schedule_time' query parameter from the URL.
     * @returns The 'class_schedule_time' parameter value, or null if not found.
     */
    static getClassScheduleTime(): string | null {
        return QueryString.getParam('class_schedule_time');
    }


    static getClassScheduleAutoStart(): string | null {
        return QueryString.getParam('class_schedule_autostart');
    }


    /**
     * Retrieves the 'set_date' query parameter from the URL.
     * @returns The 'set_date' parameter value, or null if not found.
     */
    static getSetDate(): string | null {
        return QueryString.getParam('set_date');
    }

    /**
     * Retrieves the 'set_time' query parameter from the URL.
     * @returns The 'set_time' parameter value, or null if not found.
     */
    static getSetTime(): string | null {
        return QueryString.getParam('set_time');
    }


    /**
     * Retrieves the log level from the query string.
     * @returns The log level as a string, or null if it is not present in the query string.
     */
    static getLogLevel(): string {
        let level: 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'silent' = 'info';

        const logLevel = QueryString.getParam('log_level');

        if (logLevel) {
            level = logLevel as 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'silent';
        }

        return level;
    }


    static getStudioId(): string | null {
        return QueryString.getParam('studio_id');

    }
}
