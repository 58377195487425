import React from "react";
import "./WeekDays.css";
import { BookingData } from "../../types/booking.type";
import { DateTimeUtils } from "../../utils/datetime.utils";
import { useTranslation } from "react-i18next";

interface WeekDaysProps {
  booking: BookingData[] | undefined;
  imgpath?: string | undefined;
}

const WeekDays: React.FC<WeekDaysProps> = ({ booking, imgpath}): JSX.Element => {
  const { t } = useTranslation(); // Use useTranslation hook

  let getImage = function(image: string | null): string {
    
    if (imgpath && image) {
      return imgpath + image;
    }
    return "/img/default_workout_icon.png";
  }

  return (
    <div className="horCompWeekDays">
      <div className="horCompDays2">
        {booking && booking.map((data, index) => (
          <div
            key={index}
            className={index === 0 ? "horCompDay" : "horCompDay2"}
          >
            <img className="logoCalendar" alt="Logo calendar" src={getImage(data.image)} />
            <div className="textWrapper">
              {index === 0 
                ? t("today") // Use "today" as the translation key for index 0
                : t(`week_day_short.${DateTimeUtils.getDayOfWeek(data.date) ?? 'default'}`)}
            </div>
            <div className="textWrapper2">{DateTimeUtils.formatDate(data.date)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeekDays;