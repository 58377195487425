import { BookingClassesWrapper } from "./booking.type";
import { ClassSchedule } from "./class.type";
import { WorkoutScreenData } from "./screen.type";

export interface Events {
    classWorkoutAboutToStart: ClassSchedule;
    classDataUpdated: ClassSchedule;
    classWorkoutStart: ClassSchedule;
    classNextWorkout: ClassSchedule;
    newScreenData: WorkoutScreenData;
    bookingClassesWrapper: BookingClassesWrapper;
    movementDataUpdated: ClassSchedule;
    timerNextWorkout: ClassSchedule;
    timerStartWorkout: ClassSchedule;
}

export enum RenderEvents {
    classWorkoutAboutToStart = 'classWorkoutAboutToStart',
    classDataUpdated = 'classDataUpdated',
    classWorkoutStart = 'classWorkoutStart',
    classNextWorkout = 'classNextWorkout',
    bookingClassesWrapper = 'bookingClassesWrapper',
    movementDataUpdated = 'movementDataUpdated',
    timerNextWorkout = 'timerNextWorkout',
    timerStartWorkout = 'timerStartWorkout',
}

export enum CoreEvents {
    classWorkoutAboutToStart = 'classWorkoutAboutToStart',
    classDataUpdated = 'classDataUpdated',
    classWorkoutStart = 'classWorkoutStart',
    classNextWorkout = 'classNextWorkout',
    bookingClassesWrapper = 'bookingClassesWrapper',
    bookingDataUpdated = 'bookingDataUpdated',
    movementDataUpdated = 'movementDataUpdated',
}


export enum MqttEvents {
    onMessage = 'mqttOnMessage',
    sendMessage = 'mqttSendMessage',
    connect = 'mqttConnect',
    disconnect = 'mqttDisconnect',
}