import React from "react";
import { Flame } from "../Flame/Flame";
import "./style.css";
import { ProgressCircle } from "../ProgressCircle";
import { TimerControl } from "../../types/timer.type";
import { TimerScreenData } from "../../types/screen.type";
import { useTranslation } from "react-i18next";

interface StartingClassInProps {
  message?: string;
  initialTotalTime?: number;
  color?: "red" | "green" | "yellow" | "default";
  timerScreenData?: TimerScreenData;
  timerControlRef?: React.RefObject<TimerControl>;
}

export const StartingClassIn: React.FC<StartingClassInProps> = ({ message = "", initialTotalTime = 0, color, timerControlRef }):JSX.Element => {
  const { t } = useTranslation();

  message = "stay.get_ready";
  return (
    <div className="starting-class-in">
      <div className="header-wrapper">
        <header className="header">
          <div className="content">
            <div className="container">
              <div className="text">
              <img className="dumbbell1" alt="Workout Icon" src="/img/flame.svg" />
              <div className="text-wrapper">{t(message)}</div>
              </div>
            </div>
            <ProgressCircle initialTotalTime={initialTotalTime} color={color} ref={timerControlRef} />

          </div>
        </header>
      </div>
    </div>
  );
};
