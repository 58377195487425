import React, { useEffect } from "react";
import "./Loading.css";

interface LoadingProps {
  message?: string; // optional prop to allow customization
  onLoad?: () => void;
}

const Loading: React.FC<LoadingProps> = ({ message = "text", onLoad }: LoadingProps): React.JSX.Element => {

  useEffect(() => {
    if (onLoad) {
      onLoad(); // Notify that this component has finished loading
    }
  }, [onLoad]);
  
  return (
    <div className="logo">
      <div className="text">{message}</div>
    </div>
  );
};

export default Loading;