import React from "react";
import "./Stay.css";
import { useTranslation } from "react-i18next";


interface StayProps {
  message?: string; // optional prop to allow customization
}

const Stay: React.FC<StayProps> = ({ message = "text" }: StayProps): React.JSX.Element => {
  
  const { t } = useTranslation(); // Use useTranslation hook


  return (
    <div className="gif">
      <div className="text">{t(`stay.${message}`)}</div>
    </div>
  );
};

export default Stay;