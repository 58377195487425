// dohiit-tvs/src/screens/HorBooking/HorBookingStyles.ts

import styled from "styled-components";

export const StyledHorBooking = styled.div`
  background-color: #0c0c0c;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;

  & .hor-comp-container-wrapper {
    background-color: var(--color-styles-foundation-dark-dark-500);
    height: 1080px;
    width: 1920px;
  }

  & .hor-comp-container {
    align-items: center;
    display: flex;
    gap: 8px;
    height: 1080px;
    padding: 32px;
    position: relative;
    width: 1920px;
  }

  & .hor-comp-inner-stack {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--color-styles-bg-dark-components);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
  }

  & .hor-comp-time {
    align-items: flex-start;
    align-self: stretch;
    background-color: var(--color-styles-foundation-dark-dark-400);
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    padding: 8px 8px 8px 24px;
    position: relative;
    width: 100%;
  }

  & .text-wrapper-9 {
    align-self: stretch;
    color: var(--color-styles-foundation-light-light-500);
    font-family: var(--heading-font-family);
    font-size: var(--heading-font-size);
    font-style: var(--heading-font-style);
    font-weight: var(--heading-font-weight);
    letter-spacing: var(--heading-letter-spacing);
    line-height: var(--heading-line-height);
    position: relative;
  }
`;