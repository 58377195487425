import React from "react";
import "./style.css";
import { ProgressCircle } from "../ProgressCircle";
import { BatteryCharging2 } from "../BatteryCharging2/BatteryCharging2";
import { useTranslation } from "react-i18next";
import { TimerControl } from "../../types/timer.type";

interface HorTvbdProps {
  type?: string;
  workoutName: string;
  totalTime: number;
  set: number;
  totalSets: number;
  hydrate_active: number; // Active hydration break index
  hydrate_total: number;  // Total number of hydration breaks
  showFinish?: boolean;
  finishMessage?: string;
  timerControlRef?: React.RefObject<TimerControl>;

}

export const HorTvbd: React.FC<HorTvbdProps> = ({
  type = "type",
  workoutName = "Workout Name",
  totalTime = 60 * 42,
  set = 3,
  totalSets = 24,
  hydrate_active = 1,
  hydrate_total = 3, // Total number of hydration breaks
  showFinish = false,
  finishMessage = "finish.finish",
  timerControlRef,
}): React.JSX.Element => {

  const { t } = useTranslation();

  const translatedFinishMessage = t(finishMessage);

  // Split title into words, translate each word, and then join them back
  const translatedTitle = workoutName
    .split(" + ") // Split the title into individual words
    .map((word) => t(`exercise_type.` + word.trim())) // Translate each word separately
    .join(" + "); // Join them back with a space

  // Function to generate the set rectangles and hydration breaks
  const generateSetProgress = () => {
    const elements = [];
    const interval = Math.floor(totalSets / (hydrate_total + 1)); // Calculate the interval between hydration breaks

    let hydrationBreaksInserted = 0; // Keep track of hydration breaks

    for (let i = 1; i <= totalSets; i++) {
      const isLastActive = i === set; // Check if it's the last active rectangle
      const isHydrateActive = type === "hydrate" ? hydrationBreaksInserted + 1 === hydrate_active : false; // Check if this hydration break is active

      elements.push(
        <div
          key={`set-${i}`}
          className={`rectangle-2 ${i <= set ? "active" : ""} ${(isLastActive && !isHydrateActive && !showFinish) ? "blinking" : ""}`} // Highlight the current or completed sets and make the last active blink, but not if there's an active hydration break
        />
      );

      // Insert hydration break at calculated intervals, but ensure no more than 'hydrate' breaks
      if (i % interval === 0 && hydrationBreaksInserted < hydrate_total) {
        elements.push(
          <BatteryCharging2
            key={`battery-${hydrationBreaksInserted}`}
            blink={isHydrateActive} // Pass blink true if this is the active hydration break
          />
        );
        hydrationBreaksInserted++;
      }
    }
    return elements;
  };

  return (
    <div className="hor-TVBD">
      <div className="hor-comp-container-wrapper">
        <div className="hor-comp-container">
          <div className="hor-comp-header">
            <div className="title">
              <img className="dumbbell1" alt="Workout Icon" src="/img/dumbbell.svg" />
              <div className="text">{translatedTitle}</div>
            </div>
          </div>

          {/* Conditionally render ProgressCircle or alternative content */}
          {showFinish ? (
              <div className="finish">
              <div className="finish-wrapper">
                <img className="finish-gif" alt="Finish" src="/img/finish.gif" />
              </div>
              <div className="finish-text">{translatedFinishMessage}</div>
            </div>
          ) : (
            <ProgressCircle initialTotalTime={totalTime} color="green" ref={timerControlRef} />
          )}

          <div className="hor-comp-progress-2">
            <div className="work-progress">
              {generateSetProgress()} {/* Generate the dynamic progress rectangles and breaks */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};