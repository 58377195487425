{
    "exercise_type": {
      "Strength": "Força",
      "Cardio": "Cardio",
      "Mobility": "Mobilidade",
      "default": "Exercício"
    },
    "period": {
      "morning": "Manhã",
      "afternoon": "Tarde",
      "night": "Noite",
      "default": "Morning"
    },
    "week_day_short": {
      "Monday": "Segunda",
      "Tuesday": "Terça",
      "Wednesday": "Quarta",
      "Thursday": "Quinta",
      "Friday": "Sexta",
      "Saturday": "Sábado",
      "Sunday": "Domingo",
      "default": "Segunda"
    },
    "today": "Hoje",
    "stay": {
      "station": "Continua nesta estação",
      "pod": "Continua neste Pod",
      "station_move": "Mudar de estação",
      "pod_move": "Mudar de Pod",
      "set_station": "Após este Set, fica na Estação",
      "set_station_move": "Após este Set, muda de Estação",
      "set_pod_move": "Após este Set, troca de Pod",
      "get_ready": "Prepara-te"
    },

      "sets_number_one": "Ainda falta {{count}} set",
      "sets_number_other": "Ainda faltam {{count}} sets",

    "stations": "Estações",
    "sets": "Sets",
    "finish": {
      "finish": "Treino concluído."
    },
    "hydrate": {
      "recover": "Recuperar"
    },
    "next_class": "Próxima aula"
  }