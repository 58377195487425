import React, { useEffect, useState } from "react";
import "./Hydrate.css";
import { useTranslation } from "react-i18next";

interface HydrateProps {
  message?: string;
}

const Hydrate: React.FC<HydrateProps> = ({ message }): React.JSX.Element => {
  const [step, setStep] = useState(1);

  const { t } = useTranslation(); // Use useTranslation hook


  useEffect(() => {
    const interval = setInterval(() => {
      setStep((prevStep) => (prevStep === 3 ? 1 : prevStep + 1));
    }, 2000); // Change step every 2 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="gif">
      <div className="battery-medium">
        <div className="overlap-group">
          <img className="vector" alt="Vector" src="/img/battery-medium-empty.svg" />

          <img
            className={`vector-1 ${step >= 1 ? "visible" : "invisible"}`}
            alt="Vector"
            src={step === 1 ? "/img/battery-medium-bar-red.svg" : step === 2 ? "/img/battery-medium-bar-yellow.svg" : "/img/battery-medium-bar-green.svg"}
          />
          <img
            className={`vector-2 ${step >= 2 ? "visible" : "invisible"}`}
            alt="Vector"
            src={step === 2 ? "/img/battery-medium-bar-yellow.svg" : "/img/battery-medium-bar-green.svg"}
          />
          <img
            className={`vector-3 ${step === 3 ? "visible" : "invisible"}`}
            alt="Vector"
            src="/img/battery-medium-bar-green.svg"
          />
        </div>
      </div>
      <div className="text-2">{t(`hydrate.${message}`)}</div>
    </div>
  );
};

export default Hydrate;