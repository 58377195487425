import React, { useEffect, useState } from "react";
import "./ProgressBar.css";
import { useTranslation } from "react-i18next";

interface ProgressBarProps {
  total?: number;
  progress?: number;
  label?: string;
  time?: number;
  hideOnTimeLeft?: number;
  bypassCacheGuid?: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  total = 10,
  progress = 0,
  label = "Stations",
  time = 0,
  hideOnTimeLeft,
  bypassCacheGuid,
}: ProgressBarProps): React.JSX.Element => {

  const [currentTime, setCurrentTime] = useState(time); // state for the current time
  const [show, setShow] = useState(true); // state for showing the notification
  const [bypassCache, setCacheGuid] = useState(bypassCacheGuid);

  const { t } = useTranslation(); // Use useTranslation hook


  useEffect(() => {
    setCacheGuid(bypassCacheGuid);
    setCurrentTime(time);
  }, [bypassCacheGuid]);

  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setCurrentTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          }
          return 0;
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [time, bypassCacheGuid]);

  useEffect(() => {
    if (hideOnTimeLeft) {
      if (currentTime <= hideOnTimeLeft) {
        setShow(false);
      } else {
        setShow(true);
      }
    }
  }, [currentTime, hideOnTimeLeft, bypassCacheGuid]);

  // Ensure progress doesn't exceed total
  const safeProgress = Math.min(progress, total);

  return (
    <div key={bypassCacheGuid}>
      {show && (
        <div className="workout-progress-bar">
          <div className="progress-bar">
            <div className="percentage">{t(`${label}`)}</div>
            <div className="exercises">
              {[...Array(total)].map((_, index) => {
                const isLastProgress = index === safeProgress - 1;
                return (
                  <div
                    key={index}
                    className={
                      index < safeProgress
                        ? isLastProgress
                          ? "rectangle blinking" // Apply blinking class to the last progress element
                          : "rectangle"
                        : "div"
                    }
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgressBar;