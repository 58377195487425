import React from "react";
import "./Finish.css";
import { useTranslation } from "react-i18next";


interface FinishProps {
  message?: string; // optional prop to allow customization
}

const Finish: React.FC<FinishProps> = ({ message = "text" }: FinishProps): React.JSX.Element => {
  
  const { t } = useTranslation(); // Use useTranslation hook


  return (
    <div className="finish">
      <div>
        <img className="finish-gif" alt="Finish" src="/img/finish.gif" />
      </div>
      <div className="text">{t(`finish.${message}`)}</div>
    </div>
  );
};

export default Finish;