import React, { useRef, useEffect } from 'react';

type SoundPlayerProps = {
    soundFile: string; // Path to the sound file
    play: boolean;     // Whether to play the sound
};

const SoundPlayer: React.FC<SoundPlayerProps> = ({ soundFile, play }): React.JSX.Element => {

    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        if (audioRef.current) {
            if (play) {
                audioRef.current.play().catch((error) => {
                    console.warn("Error playing audio:", error);
                });
            } else {
                audioRef.current.pause();
                audioRef.current.currentTime = 0; // Reset playback to the start
            }
        }
    }, [play]); // Run effect when `play` changes

    return (
        <div>
            {/* Audio element to load the sound file */}
            <audio ref={audioRef} src={soundFile} preload="auto" />
        </div>
    );
};

export default SoundPlayer;