import { BookingClasses, BookingClassesWrapper } from "../types/booking.type";
import { ClassSchedule } from "../types/class.type";
import { RenderEvents } from "../types/events.type";
import { ExerciseTask } from "../types/exercise.type";
import { RenderItem, RenderItemType } from "../types/render.types";
import { DateTimeUtils } from "../utils/datetime.utils";
import eventsService from "./events.service";

export class RenderEventService {
/*
    static emitClassAboutToStartWorkoutEvent(classSchedule: ClassSchedule) {

        const classNextWorkout: RenderItem = {  // Create a RenderItem object
            renderItemType: RenderItemType.EXERCISE_ABOUT_TO_START,
            classSchedule: classSchedule,
            date: classSchedule.date,
            time: classSchedule.start_time,
            duration: 30,
        };
        eventsService.emit(RenderEvents.classWorkoutAboutToStart, classNextWorkout);
    }

    // Emit the class workout start event
    static emitWorkoutStartEvent(classSchedule: ClassSchedule, task: ExerciseTask) {
        const renderItemInfo = {
            exerciseTask: task,
            duration: task.time
        };

        const renderItem: RenderItem = {
            renderItemType: RenderItemType.EXERCISE,
            renderItemInfo: renderItemInfo,
            classSchedule: classSchedule,
            date: classSchedule.date,
            time: classSchedule.start_time,
            duration: task.time,
        };

        eventsService.emit(RenderEvents.classWorkoutStart, renderItem);
    }
*/
    // Emit the next workout event
    static emitExerciseNextWorkoutEvent(classSchedule: ClassSchedule) {
        const classNextWorkout: RenderItem = {
            renderItemType: RenderItemType.EXERCISE_NEXT_WORKOUT,
            classSchedule,
            date: classSchedule.date,
            time: classSchedule.start_time,
            duration: DateTimeUtils.calculateSecondsToTime(classSchedule.start_time),
        };
        eventsService.emit(RenderEvents.classNextWorkout, classNextWorkout);
    }

    // Emit the next workout event
    static emitTimerNextWorkoutEvent(classSchedule: ClassSchedule) {
        const classNextWorkout: RenderItem = {
            renderItemType: RenderItemType.TIMER_NEXT_WORKOUT,
            classSchedule,
            date: classSchedule.date,
            time: classSchedule.start_time,
            duration: DateTimeUtils.calculateSecondsToTime(classSchedule.start_time),
        };
        eventsService.emit(RenderEvents.timerNextWorkout, classNextWorkout);
    }

    static emitBookingDataEvent(booking: BookingClasses | undefined) {
        if (!booking) {
            return;
        }

        let bookingClassesWrapper: BookingClassesWrapper = {
            bookingClasses: booking
        };

        let renderItemInfo = {
            bookingClassesWrapper: bookingClassesWrapper
        };

        const renderItem: RenderItem = {
            renderItemType: RenderItemType.BOOKING,
            renderItemInfo: renderItemInfo,
        };

        eventsService.emit(RenderEvents.bookingClassesWrapper, renderItem);
    }

    // Emit movement data updated event
    static emitMovementEvent(classSchedule: ClassSchedule) {
        const renderItem: RenderItem = {
            renderItemType: RenderItemType.MOVEMENT,
            classSchedule,
            date: classSchedule.date,
            time: classSchedule.start_time,
        };
        eventsService.emit(RenderEvents.movementDataUpdated, renderItem);
    }

    // Emit timer event
    static emitTimerEvent(classSchedule: ClassSchedule, task: ExerciseTask) {
        if (!classSchedule || !task) {
            return;
        }

        const renderItemInfo = {
            exerciseTask: task,
            duration: task.time
        };

        const timerData: RenderItem = {
            renderItemType: RenderItemType.TIMER,
            renderItemInfo: renderItemInfo,
            classSchedule: classSchedule,
            date: classSchedule.date,
            time: classSchedule.start_time,
            duration: task.time,
        };
        eventsService.emit(RenderEvents.timerStartWorkout, timerData);
    }


    static emitExerciseTaskEvent = (classSchedule: ClassSchedule, task: ExerciseTask) => {
        if (!classSchedule || !task) {
            return;
        }

        const renderItemInfo = {
            exerciseTask: task,
            duration: task.time
        };

        const renderItem: RenderItem = {  // Create a RenderItem object
            renderItemType: RenderItemType.EXERCISE,
            renderItemInfo: renderItemInfo,
            classSchedule: classSchedule,
            date: classSchedule.date,
            time: classSchedule.start_time,
            duration: task.time,
        };
        eventsService.emit(RenderEvents.classWorkoutStart, renderItem);
    }

}