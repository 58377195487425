import React from "react";
import { useTranslation } from 'react-i18next'; // Import useTranslation
import { StyledHorBooking } from "./HorBookingStyles"; // Import styles
import { Booking as BookingType, BookingScreenData } from "../../types/booking.type";
import { BookingService } from "../../services/booking.service";
import { WeekDays, DaysWrapper } from "../../components";
import { TimerControl } from "../../types/timer.type";


interface BookingProps {
  screenData: BookingScreenData | undefined;
  timerControlRef?: React.RefObject<TimerControl> | null;
}

export const Booking: React.FC<BookingProps> = ({ screenData }): JSX.Element => {
  const { t } = useTranslation(); // Use useTranslation hook
  const bookingClasses = screenData?.bookingClasses;
  const bookingData = bookingClasses ? BookingService.toBookingData(bookingClasses) : [];
  const filteredClassTimesByPeriod = bookingClasses ? BookingService.filterClassTimesByPeriod(bookingData) : [];
  const booking: BookingType = BookingService.transformScheduleData(filteredClassTimesByPeriod); 
  return (
    <StyledHorBooking>
      <div className="hor-comp-container-wrapper">
        <div className="hor-comp-container">
          <div className="hor-comp-inner-stack">
            <WeekDays booking={bookingData} imgpath={screenData?.imgPath} />

            {(Object.keys(booking) as Array<keyof BookingType>).map((period) => (
              <React.Fragment key={period}>
                <div className="hor-comp-time">
                  <div className="text-wrapper-9">{t(`period.${period ?? 'default'}`)}</div>
                </div>
                {booking[period].map((times, index) => (
                  <DaysWrapper
                    key={`${period}-${index}`}
                    hours={Array.from(times).map(hour => hour || "").slice(0, 7).concat(Array(7 - times.length).fill(""))}
                  />
                ))}
              </React.Fragment>
            ))}

          </div>
        </div>
      </div>
    </StyledHorBooking>
  );
};