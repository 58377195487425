import React, { useState, useEffect } from "react";
import "./Notification.css";
import { useTranslation } from "react-i18next";

interface NotificationProps {
  message?: string; // optional prop to allow customization
  time?: number; // the total time in seconds
  showOnTimeLeft?: number; // the time (in seconds) to start showing the notification
  isNumber?: boolean;
  bypassCacheGuid?: string;
}

const Notification2 = ({
  message = "text",
  time = 0, // default to 0 if no time is provided
  showOnTimeLeft = 0, // default to 0 if no showOnTimeLeft is provided
  isNumber = false,
  bypassCacheGuid,

}: NotificationProps): React.JSX.Element => {
  const [currentTime, setCurrentTime] = useState(time); // state for the current time
  const [show, setShow] = useState(false); // state for showing the notification
  const [bypassCache, setCacheGuid] = useState(bypassCacheGuid);

  const { t } = useTranslation(); // Use useTranslation hook

  useEffect(() => {
    setCacheGuid(bypassCacheGuid);
    setCurrentTime(time);
  }, [bypassCacheGuid]);


  useEffect(() => {
    // only set an interval if the time is greater than 0
    if (time > 0) {
      const interval = setInterval(() => {
        setCurrentTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          }
          return 0;
        });
      }, 1000);
      return () => {
        clearInterval(interval); // cleanup interval on component unmount
      }
    }
  }, [time, bypassCacheGuid]);

  useEffect(() => {
    // show the notification when currentTime is less than or equal to showOnTimeLeft
    if (currentTime <= showOnTimeLeft) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [currentTime, showOnTimeLeft, bypassCacheGuid]);

  return (<div>
    {message && (
      <div key={bypassCacheGuid}> {/* Unique key forces re-render when changed */}
        {show && (!isNumber && isNaN(Number(message))) && <div className="notification"><p className="text">{t(`stay.${message}`)}</p></div>}
        {show && (isNumber || !isNaN(Number(message))) && <div className="notification"><p className="text">{t('sets_number', { count: Number(message) })}</p></div>}</div>)}
      </div>
  );
};

export default Notification2;